'use strict';
import App from 'App';
import Config from 'Config';
import DomainManager from 'DomainManager';
import Context from 'models/context/Context';
import CClientConfiguration from 'parametrage/CClientConfiguration';
import SubmitRecordRequest from 'server/protocol/request/task/SubmitRecord';
import Server from 'server/Server';
import User from 'User';
import I18NUtils from 'utils/i18n/I18NUtils';
import StringUtils from 'utils/StringUtils';
import HeaderListPopup from 'views/listing/HeaderListPopup';
import RecordPopupView from 'views/record/RecordPopup';
import { SAIView } from '../../Additions';
import { Field } from '../../parametrage/structures/Field';
import ModalConfirmationDialog from '../../views/modal/ModalConfirmationDialog';
import { TaskView, TaskWindowType, TaskPresentation } from '../../views/tasks/Task';
import { DomainContext } from '../../parametrage/DomainContext';
import GetTaskRequest from '../../server/protocol/request/task/GetTask';
import LoadingMask from 'views/loading/LoadingMask';
import TaskFactory from 'factory/TaskFactory';
import { TaskPopupView } from '../../views/record/TaskPopup';
import { Task } from '../../parametrage/structures/Task';
import RecordView from '../../views/record/Record';
import DeviceUtils from '../DeviceUtils';
import ThemeManager from 'parametrage/ThemeManager';
import { ColorTheme } from '../../parametrage/lookandfeel/ColorTheme';
import DialogUtils from '../DialogUtils';
import GridActionRequest from '../../server/protocol/request/task/GridAction';
import { Column } from '../../models/listing/Header';
import { ViewTask } from '../../views/tasks/View';
let $ = require('jquery');
let moment = require('moment');

class NotificationManager extends SAIView{
    private currentTaskContext: Array<TaskView>;
    private initialTaskContext: TaskView;
    private currentTaskContextFiller: (context:{[propId: string]: string}) => void;
    public context: {[propId: string]: string};
    private domainContext: DomainContext;
    public static USER_CANCELLED: Symbol = Symbol();
    public static FAILURE: Symbol = Symbol();
    public static SUCCESS: Symbol = Symbol();
    private paused: boolean;
    private startIndex: number;
    private currentNotification: any;
    private taskpopup: TaskPopupView;
    private defaultModalColor: string;
    private sessionVars: any;

    initialize(options){
        this.defaultModalColor = '#F6A115';
        this.reset();

        this.currentTaskContext = [];
        this.currentTaskContext.push(options.currentTaskContext);
        this.currentTaskContextFiller = options.contextFiller;

        if(this.currentTaskContextFiller){
            this.currentTaskContextFiller(this.context);
        }

        this.initialTaskContext = this.currentTaskContext[0];
        this.domainContext = options.domainContext;
        this.sessionVars = options.sessionVars ? options.sessionVars : {};
    }

    reset(){
        this.context = {};
        this.paused = false;
        this.startIndex = 0;
        this.currentNotification = undefined;
        this.context['todayDate'] = moment().format('YYYY-MM-DD HH:mm:ss');
        this.context['CURRENT_USER_ID'] = User.getId();
        this.trigger('notificationEnd');
    }

    handle(notification?){
        if(this.currentNotification === undefined){
            this.currentNotification = notification;
        }else if(this.startIndex === 0){
            App.displayErrorMessage(I18NUtils.getMessage('NOTIFICATION_CONCURRENT_NOTIFICATION', User.getLocale(), {}));
            return NotificationManager.FAILURE;
        }

        if(this.currentNotification.actions) {
            for(var opKey = this.startIndex; opKey < this.currentNotification.actions.length && !this.paused; opKey++){
                var currentOp = this.currentNotification.actions[opKey];

                var type = currentOp.type;
                if(!type || typeof type !== 'string'){
                    App.displayErrorMessage(I18NUtils.getMessage('NOTIFICATION_MISSING_ACTION_TYPE', User.getLocale(), {}));
                    return NotificationManager.FAILURE;
                }
                type = StringUtils.underscoreToCamelCase(type);

                if(!this[type]){
                    App.displayErrorMessage(I18NUtils.getMessage('NOTIFICATION_MISSING_ACTION_HANDLER', User.getLocale(), {
                        actionId: type
                    }));
                    return NotificationManager.FAILURE;
                }

                var success = this[type](currentOp, this.context);
                if(success === NotificationManager.FAILURE){
                    App.displayErrorMessage(I18NUtils.getMessage('NOTIFICATION_ACTION_FAILURE', User.getLocale(), {
                        actionId: type
                    }));
                    return NotificationManager.FAILURE;
                }else if(success === NotificationManager.USER_CANCELLED){
                    //early terminantion of the process can be allowed
                    this.reset();
                    return NotificationManager.FAILURE;
                }

                this.startIndex++;
            }
        }

        if(!this.paused){
            this.reset();
        }
    }

    pauseExecution(){
        this.paused = true;
    }

    resumeExecution(state){
        this.paused = false;
        if(state === NotificationManager.USER_CANCELLED || state === NotificationManager.FAILURE){
            this.reset();
            this.trigger('notificationEnd');
            return;
        }else if(state === NotificationManager.SUCCESS){
            this.handle();
        }else{
            App.displayErrorMessage(I18NUtils.getMessage('NOTIFICATION_MAJOR_FAILURE', User.getLocale(), {
                state : state
            }));
            this.trigger('notificationEnd');
            return NotificationManager.FAILURE;
        }
    }

    refreshDashboard(action, context) {
        if (App.views.applicationDesktop !== undefined) {
            App.views.applicationDesktop.refreshDashboard(true);
        }
    }

    refresh(action, context) {
        var currentTask = this.currentTaskContext[this.currentTaskContext.length - 1];
        var navigator = currentTask.getNavigator();
        if(navigator) {
            navigator.refreshData();
        }
        if(currentTask instanceof ViewTask) {
            currentTask.getView();
        }
    }

    showSelectGrid(action, context){
        this.pauseExecution();

        if(!action.taskId && this.currentTaskContext.length > 0) {
            action.taskId = this.currentTaskContext[this.currentTaskContext.length - 1].getConfig().getId();
        }

        if(!action.taskId){
            App.displayErrorMessage(I18NUtils.getMessage('NOTIFICATION_MISSING_PARAMETER', User.getLocale(), {
                actionId: 'showSelectGrid',
                parameter: 'taskId'
            }));
            return NotificationManager.FAILURE;
        }

        if(!action.gridViewId){
            App.displayErrorMessage(I18NUtils.getMessage('NOTIFICATION_MISSING_PARAMETER', User.getLocale(), {
                actionId: 'showSelectGrid',
                parameter: 'gridViewId'
            }));
            return NotificationManager.FAILURE;
        }

        /*if(!action.filterId){
            App.displayErrorMessage(I18NUtils.getMessage('NOTIFICATION_MISSING_PARAMETER', User.getLocale(), {
                actionId: 'showSelectGrid',
                parameter: 'filterId'
            }));
            return NotificationManager.FAILURE;
        }*/
        let defaultFilter = undefined;
        if(!action.filterId) {
            defaultFilter = 'DEFAULT_FILTER';
        }

        let gridId = CClientConfiguration.resolveTextProperties(action.gridViewId, this.context);
        let taskId = CClientConfiguration.resolveTextProperties(action.taskId, this.context);
        CClientConfiguration.getGridView(gridId, this.domainContext)
        .then((gridConfig) => {
            var proms = [];
            if(!gridConfig.getConfig().autoGenerated) {
                proms.push(CClientConfiguration.getTableForGridView(gridId, this.domainContext));
            }
            Promise.all(proms)
                .then(([tableConfig]) => {
                    let iconConfigPromise = [];
                    iconConfigPromise.push(CClientConfiguration.getGridIconConfig(gridId, taskId, this.domainContext));
                    if(tableConfig) {
                        iconConfigPromise.push(CClientConfiguration.getTableIconConfig(tableConfig.getId(),taskId, this.domainContext));
                    }

                    Promise.all(iconConfigPromise)
                        .then(([gridConfig, tableConfig]) => {
                            let iconConfig = undefined;
                            if(gridConfig || tableConfig) {
                                iconConfig = Object.assign({}, gridConfig, tableConfig);
                            }
                            var newHeaderPop = new HeaderListPopup({
                                taskId: taskId,
                                gridId: gridId,
                                filterId: defaultFilter ? defaultFilter : CClientConfiguration.resolveTextProperties(action.filterId, this.context),
                                title: CClientConfiguration.resolveTextProperties(action.title, this.context),
                                foreignKey: CClientConfiguration.resolveTextProperties(action.foreignKey, this.context),
                                domainContext: this.domainContext,
                                iconConfig: iconConfig,
                                itemClass: 'modal-item',
                                sessionVars: this.sessionVars
                            });
                    
                            this.listenTo(newHeaderPop, 'headerListPopup.onPrepareContext', this.onContextRequested.bind(this, action));
                    
                            var me = this;
                    
                            this.listenToOnce(newHeaderPop, 'selection', function(selection, row){
                                me.context['select_grid_selection'] = selection;
                                me.context['selection_result'] = selection;
                                var values = row.getColumnsValues();
                                var columns = row.getHeaders().getColumnsMap();
                                Object.keys(values).forEach((key:string) => {
                                    var col:Column = columns[key];
                                    me.context['select_grid_selection_'+col.getDatafieldId()] = values[key].humanValue;
                                })
                                me.resumeExecution(NotificationManager.SUCCESS);
                            });
                    
                            this.listenToOnce(newHeaderPop, 'userCancelled', function(){
                                me.resumeExecution(NotificationManager.USER_CANCELLED);
                            });
                    
                            this.listenToOnce(newHeaderPop, 'error', function(){
                                me.resumeExecution(NotificationManager.FAILURE);
                            });
                    
                            newHeaderPop.display();
                    
                            return NotificationManager.SUCCESS; 
                        }).catch(() => {
                            return NotificationManager.FAILURE;
                        });
                });
        });
    }

    onContextRequested(action: any, context: Context) {
        let copiedScreen = this.getScreen(action.screen.fields, true);

        for(let panelId in copiedScreen) {
            context.addPanelFields(copiedScreen[panelId],panelId);
        }
    }

    gridAction(action, context){
        this.pauseExecution();
        var copiedScreen = this.getScreen(action.screen.fields, true);

        var actionId = CClientConfiguration.resolveTextProperties(action.actionId, this.context);
        if(!actionId){
            actionId = this.context['actionId'] || 'DEFAULT_ACTION';
        }
        var popupId = CClientConfiguration.resolveTextProperties(action.popupId, this.context);
        if(!popupId){
            popupId = this.context['popupId'];
        }
        var itemId = CClientConfiguration.resolveTextProperties(action.itemId, this.context);
        if(!itemId){
            itemId = this.context['itemId'];
        }
        let contextToUse: any = this.currentTaskContext.pop();
        if(contextToUse !== undefined) {
            contextToUse.performGridAction(undefined, actionId, popupId, itemId, copiedScreen, this.onGridActionSuccess.bind(this), this.resumeExecution.bind(NotificationManager.FAILURE));
        } else {
            //We've no context, we must just make the call and that's it
            let gridIndex = 0;
            var gridContext = new Context();
            gridContext.setContextId('grid');
            //no fields in the grid context

            var recordContext = new Context();
            recordContext.setContextId('record');

            for (let id in Object.keys(copiedScreen)) {
                let panelId = Object.keys(copiedScreen)[id];
                let fields = copiedScreen[panelId];
                recordContext.addPanelFields(fields, panelId);
            }

            //There won't be any success update
            let callContext = DomainManager.getCurrentContext(User.getCurrentDomain());
            let taskId = CClientConfiguration.resolveTextProperties(action.taskId, callContext);
            let loadingReqId;
            LoadingMask.requestLoading('Action en cours')
                .then((reqId) => {
                    loadingReqId = reqId;
                    return CClientConfiguration.getTask(taskId, callContext);
                })
                .then((taskConfig) => {
                    //no task so the context is the global one
                    let gridRequest = new GridActionRequest(callContext,
                        taskConfig.getId(),
                        (taskConfig as Task).getNavigatorGridView(),
                        popupId || action.popupId,
                        itemId || action.itemId || 'UNSET',
                        actionId || action.actionId,
                        gridIndex);
                    gridRequest.setGridContext(gridContext);
                    gridRequest.setRecordContext(recordContext);
                    return Server.performRequest(gridRequest);
                })
                .catch(App.displayErrorMessage)
                .then(() => { LoadingMask.hide(loadingReqId); });
        }
    }

    onGridActionSuccess(result){
        var headers = result.headers;
        var headerMap = {};
        for(var hKey in headers){
            headerMap[headers[hKey].dataIndex] = headers[hKey];
        }

        for(var itemId in result.items){
            var columns = result.items[itemId].columns;
            for(var colId in columns){
                var datafield = headerMap[colId].datafieldId;
                this.context[datafield] = columns[colId].value;
            }
        }

        this.resumeExecution(NotificationManager.SUCCESS);
    }

    getModalTitle(action, defaultValue) {
        return this.getPresentationProperty(action, 'modalTitle', defaultValue);
    }

    getModalColor(action, defaultValue) {
        return this.getPresentationProperty(action, 'modalColor', defaultValue);
    }

    getPresentationProperty(action, property, defaultValue) {
        if (action.presentation && action.presentation.properties) {
            let props = action.presentation.properties;
            for (var i in props) {
                if (props[i].id === property) { return props[i].value; }
            }
        }
        return defaultValue;
    }

    openTask(action, context){
        this.pauseExecution();
        var taskId = CClientConfiguration.resolveTextProperties(action.taskId, this.context);
        var me = this;
        if(action.key){
            //We've a record to open
            var records = [];
            for(let keys in action.key){
                for(let key in action.key[keys]) {
                    let newKey = action.key[keys][key];
                    records.push({
                        datafieldId : newKey.datafieldId,
                        value : CClientConfiguration.resolveTextProperties(newKey.value, this.context)
                    });
                }
            }

            Promise.all([ThemeManager.getUserTheme(),
                this.buildTask(taskId, TaskView.getWindowTypeFromString(action.presentation ? action.presentation.type : null),{})])
                .then(([theme, task]) => {
                    task.setModal(true);
                    task.setDominantColor(this.getModalColor(action, Config.appTheme));
                    let newPopup = this.taskpopup = new TaskPopupView({
                        operationTitle: this.getModalTitle(action, 'Modification'),
                        operationColor: this.getModalColor(action, theme.getColor('modalColor') || this.defaultModalColor),
                        taskView: task,
                        displayMode: DeviceUtils.getDisplayModeFromSize(),
                        domainContext: me.domainContext,
                        operationState: 'modify'
                    });
                    newPopup.on('modalWindowAction', this.handleModalWindowAction.bind(this));
                    newPopup.display();
                    task.makeMainRecordRetreivalCall(records);
                });

        }else if(action.filter && action.filter.length > 0 ){
            var finalFilter = {};
            for(let key in action.filter){
                let currentFilterEntry = action.filter[key];
                finalFilter[CClientConfiguration.resolveTextProperties(currentFilterEntry.datafieldId, this.context)] = {
                    datafieldId: CClientConfiguration.resolveTextProperties(currentFilterEntry.datafieldId, this.context),
                    value: CClientConfiguration.resolveTextProperties(currentFilterEntry.value, this.context),
                    text: CClientConfiguration.resolveTextProperties(currentFilterEntry.text, this.context)
                };
            }

            App.router.navigate('#task/'+taskId, {trigger: false, replace: false});
            App.router.displayNewTask(taskId, undefined, {
                filter: finalFilter
            }).then((task) => {
                this.resumeExecution(NotificationManager.SUCCESS);
            });
        } else if(action.filterId && action.filterId.length > 0) {
            CClientConfiguration.getFilter(action.filterId, this.domainContext)
                .then((result) => {
                    var finalFilter = {};
                    let filterElements = result.getConfig().elements;
                    for(let key in filterElements) {
                        let currentFilterEntry = filterElements[key];
                        let value = currentFilterEntry.permanentValue ? currentFilterEntry.permanentValue : currentFilterEntry.value;
                        finalFilter[CClientConfiguration.resolveTextProperties(currentFilterEntry.datafieldId, this.context)] = {
                            datafieldId: CClientConfiguration.resolveTextProperties(currentFilterEntry.datafieldId, this.context),
                            value: CClientConfiguration.resolveTextProperties(value, this.context)//,
                            //text: CClientConfiguration.resolveTextProperties(currentFilterEntry.text, this.context)
                        }
                    }

                    App.router.navigate('#task/'+taskId, {trigger: false, replace: false});
                    App.router.displayNewTask(taskId, undefined, {
                        filter: finalFilter
                    }).then((task) => {
                        this.resumeExecution(NotificationManager.SUCCESS);
                    });
                })
                .catch(App.displayErrorMessage);
        } else {
            var displayType = undefined;
            if(action.modal === 'true') {
                //We need to open the task in the given mode
                Promise.all([ThemeManager.getUserTheme(),
                    this.buildTask(taskId, TaskView.getWindowTypeFromString(action.presentation ? action.presentation.type : null), {})])
                    .then(([theme, task]) => {
                        task.setDominantColor(this.getModalColor(action, Config.appTheme));
                        let newPopup = this.taskpopup = new TaskPopupView({
                            operationTitle: this.getModalTitle(action, 'Création'),
                            operationColor: this.getModalColor(action, theme.getColor('modalColor') || this.defaultModalColor),
                            taskView: task,
                            displayMode: 'desktop',
                            domainContext: me.domainContext,
                            operationState: 'create'
                        });
                        newPopup.on('modalWindowAction', this.handleModalWindowAction.bind(this));
                        newPopup.display()
                            .then(() => {
                                task.createNewEntry()
                                .then(() => {
                                    var copiedScreen = this.getScreen(action.screen.fields);
                                    for(let i in copiedScreen) {
                                        let screenItem = copiedScreen[i];
                                        screenItem.value = CClientConfiguration.resolveTextProperties(screenItem.value, me.context);
                                    }
                                    this.applyScreenValues(copiedScreen, task);
                                })
                                .catch(App.displayErrorMessage);
                            })
                });
            } else {
                if( action.presentation && action.presentation.state === 'hidden' ) {
                    displayType = 'none';
                }
                let me = this;
                App.router.displayNewTask(taskId,displayType).then((task) => {
                    var copiedScreen = this.getScreen(action.screen.fields);
                    for(let i in copiedScreen) {
                        let screenItem = copiedScreen[i];
                        screenItem.value = CClientConfiguration.resolveTextProperties(screenItem.value, me.context);
                    }

                    this.applyScreenValues(copiedScreen, task);
                    this.currentTaskContext.push(task);
                    this.resumeExecution(NotificationManager.SUCCESS);
                });
            }
        }
    }

    handleModalWindowAction(result:Symbol) {
        this.resumeExecution(result);
    }

    modifyOpenedTask (task, evt) {
        var popup = this.taskpopup;
        var role = $(evt.delegateTarget).data('role');
        if(role === 'ok'){
            /*let submitRequest = new SubmitRecordRequest(User.getCurrentDomain(), task);
            submitRequest.setOperation('modify');

            var values = popup.getRecordView().getFieldsValues();
            var recordContext = new Context();
            recordContext.setContextId('record');
            recordContext.addFields(values);
            submitRequest.setRecordContext(recordContext);

            Server.performRequest(submitRequest)
                .then(this.resumeExecution.bind(this, NotificationManager.SUCCESS))
                .catch(App.displayErrorMessage);*/
        }else{
            this.resumeExecution(NotificationManager.USER_CANCELLED);
        }
    }
    /**
     * Transforms the given action screen into a usable screen for context
     */
    getScreen(actionScreen, withPanels?: boolean){
        var copiedScreen = {};

        if(actionScreen){
            //updating the screen values if existing with the context
            for(var screenEntry in actionScreen){
                if (withPanels) {
                    var panelId;
                    if (this.currentTaskContext[this.currentTaskContext.length - 1] !== undefined) {
                        panelId = this.currentTaskContext[this.currentTaskContext.length - 1].getDatafieldPanelId(actionScreen[screenEntry].datafieldId);
                    }
                    if(!panelId) {
                        panelId = '1';
                    }
                    if(!copiedScreen[panelId]) {
                        copiedScreen[panelId] = [];
                    }
                    copiedScreen[panelId].push({
                        id: CClientConfiguration.resolveTextProperties(actionScreen[screenEntry].id, this.context),
                        key: CClientConfiguration.resolveTextProperties(actionScreen[screenEntry].id, this.context),
                        value: CClientConfiguration.resolveTextProperties(actionScreen[screenEntry].value, this.context),
                        datafieldId: CClientConfiguration.resolveTextProperties(actionScreen[screenEntry].datafieldId, this.context)
                    });
                } else {
                    var key = CClientConfiguration.resolveTextProperties(actionScreen[screenEntry].id, this.context) || screenEntry;
                    copiedScreen[key] = {
                        id: CClientConfiguration.resolveTextProperties(actionScreen[screenEntry].id, this.context),
                        key: CClientConfiguration.resolveTextProperties(actionScreen[screenEntry].id, this.context),
                        value: CClientConfiguration.resolveTextProperties(actionScreen[screenEntry].value, this.context),
                        datafieldId: CClientConfiguration.resolveTextProperties(actionScreen[screenEntry].datafieldId, this.context)
                    }
                }
            }
        }

        return copiedScreen;
    }

    /**
     * Opens the given url in a new window/tab based on browser preferences
     * @param {*} action The action root node of the action
     * @param {*} context The notification context
     */
    openUrl(action, context) {
        let url = CClientConfiguration.resolveTextProperties(action.text, context);
        if(url === undefined || url.trim() === '') {
            App.displayErrorMessage(I18NUtils.getMessage('NOTIFICATION_MISSING_VALUE', User.getLocale(), {
                actionId: 'openUrl',
                parameter: 'parameters.text'
            }));
            return NotificationManager.FAILURE;
        } else {
            window.open(url,'_blank');
            return NotificationManager.SUCCESS;
        }
    }

    applyScreenValues(actionScreen, task) {
        task.updateScreenContent(actionScreen);
        return NotificationManager.SUCCESS;
    }

    executeTask(action, context) {
        this.pauseExecution();
        var currentTask = this.currentTaskContext[this.currentTaskContext.length - 1];
        this.listenToOnce(currentTask, 'SAIExecuteTaskResult', function(result, event) {
            if(result === 'success') {
                this.currentTaskContext.pop();
                this.resumeExecution(NotificationManager.SUCCESS);
            } else {
                this.resumeExecution(NotificationManager.FAILURE);
            }
        });
        currentTask.execute();
    }

    updateScreenContent(action, context) {
        let copiedScreen = this.getScreen(action.screen.fields);
        this.applyScreenValues(copiedScreen, this.currentTaskContext[this.currentTaskContext.length - 1]);
    }

    assertTaskState(action, context) {
    }

    showWaitform(action, context) {
    }

    closeWaitform(action, context) {
    }

    confirmationDialogOkCancel(action, context) {
        this.pauseExecution();
        let text = action.text.replace('[N]','<br>');
        text = CClientConfiguration.resolveTextProperties(text, context);
        DialogUtils.displayDialog({
            title:'',
            message:text
        }, this.onConfirmationDialogOkCancelConfirm.bind(this, context));
    }

    confirmationDialogYesNoCancel(action, context) {
        this.pauseExecution();
        let text = action.text.replace('[N]', '<br>');
        text = CClientConfiguration.resolveTextProperties(text, context);
        DialogUtils.displayDialog({
            title: '',
            message: text,
            type: 'YES-NO-CANCEL'
        }, this.onConfirmationDialogCustomConfirm.bind(this, context));
    }

    confirmationDialogYesNo(action, context) {
        this.pauseExecution();
        let text = action.text.replace('[N]', '<br>');
        text = CClientConfiguration.resolveTextProperties(text, context);
        DialogUtils.displayDialog({
            title: '',
            message: text,
            type: 'YES-NO'
        }, this.onConfirmationDialogCustomConfirm.bind(this, context));
    }

    confirmationDialogComment(action, context) {
        this.pauseExecution();
        let text = action.message.replace('[N]', '<br>');
        text = CClientConfiguration.resolveTextProperties(text, context);
        DialogUtils.displayDialog({
            title: '',
            message: text,
            buttons:  [{
                    id: 'confirm',
                    icon: 'check-circle',
                    text: 'Ok'
                }
            ]
        }, this.onConfirmationDialogOkCancelConfirm.bind(this, context));
    }

    confirmationDialogCustom(action, context) {
        this.pauseExecution();
        let text = action.message.replace('[N]', '<br>');
        text = CClientConfiguration.resolveTextProperties(text, context);
        let buttonsContent = [];
        for(let key in action.button) {
            var someBut = action.button[key];
            buttonsContent.push({
                id: someBut.id,
                text: someBut.value,
                icon: 'check-circle'
            });
        }

        DialogUtils.displayDialog({
            title: '',
            message: text,
            buttons: buttonsContent
        }, this.onConfirmationDialogCustomConfirm.bind(this, context));

        return NotificationManager.SUCCESS;
    }

    onConfirmationDialogCustomConfirm(context, action) {
        if (action === 'cancel') {
            this.resumeExecution(NotificationManager.USER_CANCELLED);
        } else {
            context['response'] = action.toUpperCase();
            context['user_choice'] = action.toUpperCase();
            this.resumeExecution(NotificationManager.SUCCESS);
        }
    }

    onConfirmationDialogOkCancelConfirm(context, action) {
        if(action === 'cancel') {
            this.resumeExecution(NotificationManager.USER_CANCELLED);
        } else {
            context['response'] = 'OK';
            context['user_choice'] = 'OK';
            this.resumeExecution(NotificationManager.SUCCESS);
        }
    }

    callNotification(action, context) {
        this.pauseExecution();
        var lastElementIndex = this.currentTaskContext.length - 1;
        if(this.currentTaskContext.length === 0 || this.currentTaskContext[lastElementIndex] === null || this.currentTaskContext[lastElementIndex] === undefined) {
            this.currentTaskContext.push(this.initialTaskContext);
            lastElementIndex = this.currentTaskContext.length - 1;
        }
        let remoteManager = new NotificationManager({
            currentTaskContext: this.currentTaskContext[lastElementIndex],
            domainContext: this.domainContext,
            contextFiller: this.copyContext.bind(this, context),
        })
        if(action.notificationSource === 'remote') {
            let remoteArgs = this.parseRemoteArguments(action.remoteArguments);
            for(let key in remoteArgs) {
                remoteArgs[key] = StringUtils.resolveTextProperties(remoteArgs[key], remoteManager.context);
            }

            CClientConfiguration.getRemoteNotification(action.notificationId, action.remoteTask, remoteArgs)
                .then((notif) => {
                    remoteManager.handle(notif);
                    this.resumeExecution(NotificationManager.SUCCESS);
                })
                .catch((err) => {
                    App.displayErrorMessage(err);
                    this.resumeExecution(NotificationManager.FAILURE);
                });
        } else {
            remoteManager.handle(action);
            this.resumeExecution(NotificationManager.SUCCESS);
        }
    }

    copyContext(existingContext, newContext) {
        Object.assign(newContext, existingContext);
    }

    parseRemoteArguments(remoteArguments) {
        remoteArguments = decodeURIComponent(remoteArguments);
        let result = {};
        let splitArgs = remoteArguments.split('&');
        for(let i in splitArgs) {
            let argSplit = splitArgs[i].split('=');
            result[argSplit[0]]=argSplit[1];
        }
        return result;
    }

    serverDeleteEntry(action, context) {
        this.pauseExecution();
        var recordContext = new Context();

        CClientConfiguration.getTask(action.taskId, this.domainContext)
            .then((task: Task) => {
                let panels = task.getPanels();
                for(let i in panels) {
                    recordContext.addPanelFields([],panels[i].getId());
                }

                let keyContext = new Context({contextId: 'record'});
                keyContext.addFields(action.key[0]);

                let deleteEntryRequest = new SubmitRecordRequest(this.domainContext, action.taskId);
                deleteEntryRequest.setOperation('delete');
                deleteEntryRequest.setKeyContext(keyContext);

                deleteEntryRequest.setRecordContext(recordContext);

                Server.performRequest(deleteEntryRequest)
                .then(this.resumeExecution.bind(this, NotificationManager.SUCCESS))
                .catch((error) => {
                    App.displayErrorMessage(error);
                    this.resumeExecution(NotificationManager.FAILURE);
                });
            })
            .catch((error) => {
                App.displayErrorMessage(error);
                this.resumeExecution(NotificationManager.FAILURE);
            });
    }

    buildTask(taskName: string, windowType: TaskWindowType, presentation: {[propId: number]: boolean}) : Promise<TaskView> {
        let newRequest = new GetTaskRequest(this.domainContext, taskName);
        let loadingReqId;
        return new Promise((accept, reject) => {
            LoadingMask.requestLoading('Chargement de la tâche')
                .then((reqId) => {
                    loadingReqId = reqId;
                    return Promise.all([Server.performRequest(newRequest), CClientConfiguration.getTask(taskName, this.domainContext)]);
                })
                .then(([result, task]) => {
                    accept(TaskFactory.createTask(task, result, this.domainContext,{windowType: windowType, presentation: presentation}));
                })
                .catch((error) => {
                    App.displayErrorMessage(error);
                    reject(error);
                })
                .then(() => { LoadingMask.hide(loadingReqId);});
        });
    }

    setSessionvars(action, context) {
        if(action.vars) {
            for(let i = 0; i < action.vars.length; i++) {
                let sessionVar = action.vars[i];
                this.sessionVars[sessionVar.id] = CClientConfiguration.resolveTextProperties(sessionVar.value, context);
            }
        }
    }

}

export default NotificationManager;
